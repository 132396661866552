






import { Component, Vue } from 'vue-property-decorator'
import Nettbutikker from '@/components/Nettbutikker.vue'

Vue.filter('removeUnderscore', (value: string) => {
  if (!value) return
  return value.replace(/_/g, ' ')
})

@Component({
  components: {
    Nettbutikker
  }
})
export default class App extends Vue {}
